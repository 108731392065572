import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  constructor() {

  }
  toggleClose = function(){
    if($("#body").hasClass("menu-open")){
      $("#tgl").trigger( "click" );
    }
  }
  closeAll = function(){

    $(".has-sub").each(function(){
      if($(this).hasClass("open")){
        $(this).removeClass("open");
        //toggleClose();
      }
    });
    this.toggleClose();
  }
  ngOnInit() {

  }

}
