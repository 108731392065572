import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TableroComponent } from './views/tablero/tablero.component';
import { LoginComponent } from './views/login/login.component';
import { CollectionComponent } from './views/collection/collection.component';
import { RegisterComponent } from './views/register/register.component';
import { RecoveryComponent } from './views/recovery/recovery.component';
import { PanelComponent } from './common/panel/panel.component';

const app_routes: Routes = [
  {
    path : '',
    pathMatch:'full',
    redirectTo: 'login'
  },
  {
    path : 'login',
    component: LoginComponent,
    data: {
      title: 'CloudCFDI - Login'
    }
  },
  {
    path : 'register',
    component: RegisterComponent,
    data: {
      title: 'CloudCFDI - Registrarse'
    }
  },
  {
    path : 'recovery',
    component: RecoveryComponent,
    data: {
      title: 'CloudCFDI - Recuperar cuenta'
    }
  },
  {
    path : 'panel',
    component: PanelComponent,
    children:[
      {
        path : 'tablero',
        loadChildren : './views/tablero/tablero.module#TableroModule',
        data: {
          title: 'CloudCFDI - Tablero'
        }
      },
      {
        path : 'emitidos',
        loadChildren : './views/emitidos/emitidos.module#EmitidosModule',
        data: {
          title: 'CloudCFDI - Emitidos'
        }
      },
      {
        path : 'clientes',
        loadChildren : './views/clientes/clientes.module#ClientesModule',
        data: {
          title: 'CloudCFDI - Clientes'
        }
      },
      {
        path : 'certificados',
        loadChildren : './views/certificados/certificados.module#CertificadosModule',
        data: {
          title: 'CloudCFDI - Certificados'
        }
      },
      {
        path : 'documentos',
        loadChildren : './views/documentos/documentos.module#DocumentosModule',
        data: {
          title: 'CloudCFDI - Folios y Series'
        }
      },
      {
        path : 'recibidos',
        loadChildren : './views/recibidos/recibidos.module#RecibidosModule',
        data: {
          title: 'CloudCFDI - Recibidos'
        }
      },
      {
        path : 'cobranza',
        loadChildren : './views/collection/collection.module#CollectionModule',
        data: {
          title: 'CloudCFDI - Cobranza'
        }
      },
      {
        path : 'cfdi/ingreso',
        loadChildren : './views/cfdi/ingreso/ingreso.module#IngresoModule',
        data: {
          title: 'CloudCFDI - Ingreso'
        }
      },
      {
        path : 'cfdi/egreso',
        loadChildren : './views/cfdi/egreso/egreso.module#EgresoModule',
        data: {
          title: 'CloudCFDI - Egreso'
        }
      },
      {
        path : 'cfdi/pago',
        loadChildren : './views/cfdi/pago/pago.module#PagoModule',
        data: {
          title: 'CloudCFDI - Pago'
        }
      },
      {
        path : 'cfdi/nomina',
        loadChildren : './views/cfdi/nomina/nomina.module#NominaModule',
        data: {
          title: 'CloudCFDI - Nomina'
        }
      },
      {
        path : 'emisores',
        loadChildren : './views/emisores/emisores.module#EmisoresModule',
        data: {
          title: 'CloudCFDI - Emisores'
        }
      },
      {
        path : 'cuenta',
        loadChildren : './views/cuenta/cuenta.module#CuentaModule',
        data: {
          title: 'CloudCFDI - Mis datos'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(app_routes, {onSameUrlNavigation: 'reload'})
  ],
  exports:[
    RouterModule
  ],
  declarations: [],
  providers: []
})
export class AppRoutingModule {}
