import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { User } from '../../interfaces/user';
import { ApiService } from '../../servicios/api.service';
import { SesionService } from '../../servicios/sesion.service';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private apiService:ApiService, private sesionService:SesionService) {

  }
  @ViewChild('username') username: ElementRef;
  error: boolean = false;
  isLoading: boolean = false;
  loginResponse: any = {};
  user:User = {
    username: "",
    password: "",
    remember: false
  }

  rememberChange = () =>{
    this.user.remember = !this.user.remember;
  };
  login = () =>{
    this.error = false;
    this.isLoading = true;
          let params = {
            usuario: sha256(this.user.username),
            password: sha256(this.user.password)
          }
          this.apiService.login(params).subscribe( loginResponse => {
            this.loginResponse = loginResponse;
            if(this.loginResponse.status === 1){

              this.error = false;
              this.sesionService.put("usuario", this.loginResponse.usuario);
              this.sesionService.put("nombres", this.loginResponse.nombres);
              this.sesionService.put("apellidos", this.loginResponse.apellidos);
              this.sesionService.put("current_rfc", this.loginResponse.emisores[0].rfc_emisor);
              this.sesionService.put("current_api_key", this.loginResponse.emisores[0].api_key);
              this.sesionService.putObject("emisores", this.loginResponse.emisores);

              //this.router.navigate(["/panel/tablero"]);
              window.location.href = '/panel/tablero';
            }else{
              setTimeout(() => this.username.nativeElement.focus(), 0);
              this.error = true;
              this.isLoading = false;
            }
          });

  };

  ngOnInit() {

    //this.hash = SHA256("demo");
    //console.log(sha256('demo'));
    //console.log(SHA256("abc"));
  }
}
