import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import * as $ from 'jquery';
import { LaddaModule } from 'angular2-ladda';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { FooterComponent } from './common/footer/footer.component';
//import { HomeComponent } from './views/home/home.component';
//import { CollectionComponent } from './views/collection/collection.component';
import { LoginComponent } from './views/login/login.component';
import { PanelComponent } from './common/panel/panel.component';
import { RegisterComponent } from './views/register/register.component';
import { RecoveryComponent } from './views/recovery/recovery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng2-select2';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    //HomeComponent,
    //CollectionComponent,
    LoginComponent,
    PanelComponent,
    RegisterComponent,
    RecoveryComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    CookieModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    LaddaModule,
    NgbModule,
    Select2Module
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
