import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
@Injectable({
  providedIn: 'root'
})
export class SesionService {

  constructor(private cookieService:CookieService) { }

  put(key, value){
    return this.cookieService.put(key, value);
  }
  get(key){
    return this.cookieService.get(key);
  }
  putObject(key, object){
    return this.cookieService.putObject(key, object);
  }
  getObject(key){
    return this.cookieService.getObject(key);
  }
  logout(){
    return this.cookieService.removeAll();
  }
  remove(key){
    return this.cookieService.remove(key);
  }
  isLoged(){
    if(this.cookieService.get('usuario') && this.cookieService.getObject('emisores') && this.cookieService.get('current_rfc') && this.cookieService.get('current_api_key')){
      return true;
    }
    return false;
  }


}
