import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../servicios/sesion.service';
import { ApiService } from '../../servicios/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  emisorSeleccionado : any = {};
  emisores : any = [];
  params : any = {
    usuario: this.sesionService.get('usuario')
  }
  constructor(private router: Router, private apiService:ApiService, private sesionService: SesionService) {
    this.apiService.getEmisores(this.params).subscribe( (emisores:any) => {
      for(var i = 0; i < emisores.emisores.length; i++){
        if(emisores.emisores[i].rfc == sesionService.get('current_rfc')){
          this.emisorSeleccionado = emisores.emisores[i].rfc+'-'+emisores.emisores[i].api_key;
        }
        this.emisores.push(emisores.emisores[i]);
      }
    });
  }

  toggleClose = function(){
    if($("#body").hasClass("menu-open")){
      $("#tgl").trigger( "click" );
    }
  }
  closeAll = function(){

    $(".has-sub").each(function(){
      if($(this).hasClass("open")){
        $(this).removeClass("open");
        //toggleClose();
      }
    });
    this.toggleClose();
  }

  nombres:string="";
  apellidos:string="";
  current_rfc:string="";
  logout = () =>{
    this.sesionService.logout();
    this.router.navigate(['/login']);
    if($("#body").hasClass("menu-open")){
      $("#tgl").trigger( "click" );
    }
  }

  cambio = (event) =>{

    var datos = event.split("-");
    this.sesionService.remove('current_rfc');
    this.sesionService.remove('current_api_key');
    this.sesionService.remove('api_key');

    this.sesionService.put('current_rfc',datos[0]);
    this.sesionService.put('current_api_key',datos[1]);

    $("#cerrarModalCambio").click();

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);

  }

  cerrar() {
    console.log("Entrar cerrar");
  }

  ngOnInit() {
    //this.nombres = this.sesionService.get('nombres');
    //this.apellidos = this.sesionService.get('apellidos');
    //this.current_rfc = this.sesionService.get('current_rfc');
  }

}
