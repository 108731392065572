import { Component,ElementRef, ViewChild, OnInit } from '@angular/core';
import { ApiService } from '../../servicios/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {

  constructor(private router: Router, private apiService:ApiService) {

  }
  correo : string = "";
  error: boolean = false;
  enviado: boolean = false;
  isLoading: boolean = false;
  recuperarResponse: any = {};
  @ViewChild('email') email: ElementRef;
  recuperar = () =>{

    this.error = false;
    this.enviado = false;
    this.isLoading = true;
          let params = {
            correo: this.correo
          }
          this.apiService.recuperar(params).subscribe( recuperarResponse => {
            this.recuperarResponse = recuperarResponse;
            if(this.recuperarResponse.status === 1){

              this.error = false;
              this.isLoading = false;
              this.enviado = true;
              console.log("Se envio");

            }else{
              setTimeout(() => this.email.nativeElement.focus(), 0);
              this.error = true;
              this.enviado = false;
              this.isLoading = false;
            }
          });

  };
  ngOnInit() {
  }

}
