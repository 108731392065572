import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from '../servicios/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl : string = "https://api.cloudcfdi.app";
  //private baseUrl : string = "http://localhost:3000";
  private httpOptions: any;
  constructor( private http:HttpClient, private sesionService:SesionService ) {

  }

  login(params){
    return this.http.post(this.baseUrl + "/usuarios/login", params);
  }
  editarUserData(params){
    return this.http.post(this.baseUrl + "/usuarios/editar", params);
  }
  recuperar(params){
    return this.http.post(this.baseUrl + "/usuarios/recuperar", params);
  }
  getEmisores(params){
    return this.http.post(this.baseUrl + "/usuarios/getEmisores", params);
  }
  getUserData(params){
    return this.http.post(this.baseUrl + "/usuarios/getUserData", params);
  }
  grafica(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/tablero/grafica", params, this.httpOptions);
  }
  getCfdis(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/cfdi/obtener", params, this.httpOptions);
  }
  /*
  getCfdis(params){
    return this.http.post(this.baseUrl + "/cfdi/status", params, this.httpOptions);
  }
  */
  getClientes(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/receptores/obtener", params, this.httpOptions);
  }
  getCertificados(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/certificado/obtener", params, this.httpOptions);
  }
  getDocumentos(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/documentos/obtener", params, this.httpOptions);
  }
  actualizarDocumentos(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/documentos/actualizar", params, this.httpOptions);
  }
  agregarDocumento(params){
    this.httpOptions = {
      headers: new HttpHeaders({
        'api-key':  this.sesionService.get('current_api_key')
      })
    };
    return this.http.post(this.baseUrl + "/documentos/agregar", params, this.httpOptions);
  }

}
