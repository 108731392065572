import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SesionService } from './servicios/sesion.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(titleService:Title, private router: Router,private sesionService: SesionService) {
        router.events.subscribe( (event: Event) => {
              if (event instanceof NavigationStart) {

              if(!this.sesionService.isLoged() && (event.url != "/login" && event.url != "/recovery" && event.url != "/register")){

                this.router.navigate(["/login"]);
              }
              if(this.sesionService.isLoged() && (event.url == "/login" || event.url == "/" || event.url == "/recovery" || event.url == "/register") ){
                this.router.navigate(["/panel/tablero"]);
              }

            }
            if (event instanceof NavigationEnd) {
              var title = this.getTitle(router.routerState, router.routerState.root)[0];
              titleService.setTitle(title);

            }
            if (event instanceof NavigationError) {
              console.log("termina cambio de ruta con error");
              console.log(event.error);
            }
        });

    }
    getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
